/** @format */

import React, { useState, useRef, useEffect } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";

function App() {
  const [scannedData, setData] = useState(null); // State to store scanned data
  const [scanning, setScanning] = useState(false); // State to track if scanning is active
  const [errorMessage, setErrorMessage] = useState(null); // State to store error messages
  const isMountedRef = useRef(true); // Ref to track if the component is mounted

  useEffect(() => {
    // Set isMountedRef to true when the component mounts
    isMountedRef.current = true;

    // Cleanup function to set isMountedRef to false when the component unmounts
    return () => {
      isMountedRef.current = false; // Mark the component as unmounted
      stopScan(); // Ensure scanning stops
    };
  }, []);

  const handleScan = (result) => {
    if (!isMountedRef.current) return;
    if (!result) {
      setErrorMessage("No QR code found. Please try again.");
      return;
    }

    const scannedResult =
      typeof result === "string"
        ? JSON.parse(result)
        : result.rawValue
        ? JSON.parse(result.rawValue)
        : result;

    setData(scannedResult); // Save the scanned data
    sendToWordPress(scannedResult); // Send to WordPress
    setScanning(false);
    setErrorMessage(null);
  };

  const username = "scanner1"; // Replace with your WordPress username
  const applicationPassword = "Vx3J Ssky JBKy Cu6b FbyQ Lbpo"; // Replace with your Application Password

  const credentials = btoa(`${username}:${applicationPassword}`);

  const sendToWordPress = async (data) => {
    console.log("data 44: ", data);
    console.log("data[0].rawValue: ", data[0].rawValue);
    try {
      const response = await fetch(
        "https://hostman.co.il/wp-json/custom/v1/scan",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${credentials}`, // Add Authorization header
          },
          body: JSON.stringify(JSON.parse(data[0].rawValue)),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send data to WordPress");
      }

      const responseData = await response.json();
      console.log("Data saved successfully:", responseData);
    } catch (error) {
      console.error("Error sending data to WordPress:", error);
      setErrorMessage("Failed to save data to the server.");
    }
  };

  const handleError = (error) => {
    if (!isMountedRef.current) return; // Return early if unmounted
    console.error("Error scanning QR code:", error);
    setErrorMessage("Failed to scan the QR code.");
    setScanning(false); // Stop scanning on error
  };

  const startScan = () => {
    setScanning(true); // Start scanning when the button is clicked
    setData(null); // Clear previous scannedData
    setErrorMessage(null); // Clear previous errors
  };

  const stopScan = () => {
    setScanning(false); // Stop scanning
  };

  return (
    <div className="App">
      <h1>QR Code Scanner</h1>

      {/* Start/Stop scan buttons */}
      {!scanning ? (
        <button className="scan-button" onClick={startScan}>
          Start Scan
        </button>
      ) : (
        <button onClick={stopScan}>Cancel Scan</button>
      )}

      {/* Display scanner only if scanning is active */}
      {scanning && (
        <Scanner
          onScan={handleScan}
          onError={handleError}
          style={{ width: "100%" }}
        />
      )}

      {/* Display messages based on scan results */}
      {scannedData && !scanning && (
        <div>
          <h2>Scan successful!</h2>
          {console.log("scannedData 87: ", scannedData[0].rawValue)}
          <div>
            Data from QR code:{scannedData[0].rawValue}
            {Array.isArray(scannedData) ? (
              scannedData.map((dataItem) => (
                <div key={dataItem.order_id}>{dataItem.order_id}</div>
              ))
            ) : (
              <div>{scannedData}</div>
            )}
          </div>
        </div>
      )}

      {errorMessage && (
        <div>
          <h2>{errorMessage}</h2>
        </div>
      )}

      {!scannedData && !errorMessage && !scanning && (
        <p>Click "Start Scan" to begin scanning a QR code.</p>
      )}
    </div>
  );
}

export default App;
